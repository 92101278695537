import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg width="20px" height="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" fill="#FFFFFF">         
        <g id="bot">
	<path  d="M200,272c-13.2,0-24,10.8-24,24s10.8,24,24,24s24-10.8,24-24S213.2,272,200,272z M200,304c-4.4,0-8-3.6-8-8
		s3.6-8,8-8s8,3.6,8,8S204.4,304,200,304z M312,272c-13.2,0-24,10.8-24,24s10.8,24,24,24s24-10.8,24-24S325.2,272,312,272z M312,304
		c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S316.4,304,312,304z M328,240H184c-30.9,0-56,25.1-56,56s25.1,56,56,56h144
		c30.9,0,56-25.1,56-56S358.9,240,328,240z M328,336H184c-22.1,0-40-17.9-40-40s17.9-40,40-40h144c22.1,0,40,17.9,40,40
		S350.1,336,328,336z M464,273.5V142.5c9.3-3.3,16-12.1,16-22.5c0-13.2-10.8-24-24-24s-24,10.8-24,24c0,10.4,6.7,19.2,16,22.5v130.9
		c-3.3,1.2-6.3,3-8.7,5.3C414.9,200.8,342,144,256,144c-86,0-158.9,56.8-183.3,134.8c-2.4-2.4-5.4-4.2-8.7-5.3V142.5
		c9.3-3.3,16-12.1,16-22.5c0-13.2-10.8-24-24-24s-24,10.8-24,24c0,10.4,6.7,19.2,16,22.5v130.9c-9.3,3.3-16,12.1-16,22.5v64
		c0,13.2,10.8,24,24,24c7.5,0,14.2-3.6,18.6-9c13.7,24.3,39.5,41,69.4,41h224c29.9,0,55.7-16.7,69.4-41c4.4,5.4,11.1,9,18.6,9
		c13.2,0,24-10.8,24-24v-64C480,285.6,473.3,276.8,464,273.5z M456,112c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S451.6,112,456,112z
		 M56,112c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S51.6,112,56,112z M64,360c0,4.4-3.6,8-8,8s-8-3.6-8-8v-64c0-4.4,3.6-8,8-8
		s8,3.6,8,8V360z M368,400H144c-35.3,0-64-28.7-64-64c0-97,79-176,176-176s176,79,176,176C432,371.3,403.3,400,368,400z M464,360
		c0,4.4-3.6,8-8,8s-8-3.6-8-8v-64c0-4.4,3.6-8,8-8s8,3.6,8,8V360z"/>
</g>

        </svg>
  )
}

export default Icon
