import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg width="20px" height="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" fill="#FFFFFF">         
        <g>
            <g>
                <g>
                    <path d="M420.4,91.6L335.1,6.2c0,0,0,0,0,0c-0.5-0.5-1-1-1.6-1.4c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.6-0.5-0.9-0.7
                        c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.2-0.6-0.3-1-0.5c-0.3-0.1-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-1-0.4
                        c-0.3-0.1-0.7-0.2-1-0.4c-0.3-0.1-0.6-0.2-1-0.2c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-0.1-0.8-0.1-1.1-0.2c-0.3,0-0.6-0.1-0.9-0.1
                        c-1.4-0.1-2.8-0.1-4.2,0c-0.3,0-0.6,0.1-0.9,0.1c-0.4,0.1-0.8,0.1-1.1,0.2c-0.4,0.1-0.7,0.2-1.1,0.3c-0.3,0.1-0.6,0.2-1,0.2
                        c-0.3,0.1-0.7,0.2-1,0.4c-0.3,0.1-0.6,0.2-1,0.4s-0.6,0.3-0.9,0.4c-0.3,0.2-0.7,0.3-1,0.5c-0.3,0.2-0.6,0.3-0.9,0.5
                        c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.4-0.9,0.7c-0.3,0.2-0.5,0.4-0.8,0.6c-0.5,0.4-1.1,0.9-1.6,1.4c0,0,0,0,0,0l-85.3,85.3
                        c-8.3,8.3-8.3,21.8,0,30.2s21.8,8.3,30.2,0l48.9-48.9V320c0,11.8,9.6,21.3,21.3,21.3s21.3-9.6,21.3-21.3V72.8l48.9,48.9
                        c8.3,8.3,21.8,8.3,30.2,0C428.7,113.4,428.7,99.9,420.4,91.6z"/>
                    <path  d="M262.2,390.2l-48.9,48.9V192c0-11.8-9.6-21.3-21.3-21.3c-11.8,0-21.3,9.6-21.3,21.3v247.2l-48.9-48.9
                        c-8.3-8.3-21.8-8.3-30.2,0s-8.3,21.8,0,30.2l85.3,85.3c0,0,0,0,0,0c0.5,0.5,1,1,1.6,1.4c0.2,0.2,0.5,0.4,0.8,0.6
                        c0.3,0.2,0.6,0.5,0.9,0.7c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.2,0.6,0.4,0.9,0.5c0.3,0.2,0.6,0.3,1,0.5c0.3,0.1,0.6,0.3,0.9,0.4
                        c0.3,0.1,0.6,0.2,1,0.4c0.3,0.1,0.7,0.2,1,0.4c0.3,0.1,0.6,0.2,1,0.2c0.4,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.1,1.1,0.2
                        c0.3,0,0.6,0.1,0.9,0.1c1.4,0.1,2.8,0.1,4.2,0c0.3,0,0.6-0.1,0.9-0.1c0.4-0.1,0.8-0.1,1.1-0.2c0.4-0.1,0.7-0.2,1.1-0.3
                        c0.3-0.1,0.6-0.2,1-0.2c0.3-0.1,0.7-0.2,1-0.4c0.3-0.1,0.6-0.2,1-0.4c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.2,0.7-0.3,1-0.5
                        c0.3-0.2,0.6-0.3,0.9-0.5c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.2,0.5-0.4,0.8-0.6c0.5-0.4,1.1-0.9,1.5-1.4
                        c0,0,0,0,0,0l85.3-85.3c8.3-8.3,8.3-21.8,0-30.2S270.6,381.9,262.2,390.2z"/>
                </g>
            </g>
        </g>
        </svg>
  )
}

export default Icon
