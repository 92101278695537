import { FooterLinkType } from 'uikit'
import { ContextApi } from 'contexts/Localization/types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
    // {
    //     label:"WAVX",
    //     items:[
    //         {
    //             label:"Trade Crypto",
    //             href:"/swap"
    //         },
    //         {
    //             label:"Add Liquidity",
    //             href:"/liquidity"
    //         }
    //     ]
    // },
    {
        label:"Community",
        items:[
            {
                label:"Twitter",
                href:"https://x.com/wavxofficial?s=21"
            },
            {
                label:"Telegram",
                href:"https://t.me/wavxtokencommunity"
            }
        ]
    },
    
    {
        label:"Official",
        items:[
            {
                label:"Project",
                href:"https://wavx.io/"
            },
            {
                label:"Email: info@wavx.io",
                href:""
            }            
        ]
    }
]
