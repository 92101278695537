import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg width="50px" height="50px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" fill="#FFFFFF">         
<g>
	<path d="M195.8,66.5c-0.8-1.8-2.2-3.3-4.1-4.1L63.5,9.3c-3.8-1.6-8.2,0.2-9.8,4.1L0.6,141.6c-0.8,1.8-0.8,3.9,0,5.7
		c0.8,1.8,2.2,3.3,4.1,4.1L90.7,187c0.9,0.4,1.9,0.6,2.9,0.6c1,0,2-0.2,2.9-0.6l59.6-24.7c1.8-0.8,3.3-2.2,4.1-4.1l35.7-86.1
		C196.5,70.4,196.5,68.3,195.8,66.5z M107.5,166.3l7.6-18.5l18.5,7.6L107.5,166.3z M149.1,145.6l-35.2-14.6
		c-3.8-1.6-8.2,0.2-9.8,4.1l-14.6,35.2l-72.2-29.9L64.7,26L179,73.4L149.1,145.6z"/>
	<polygon points="133.4,98 127.6,84.2 89.2,100.1 82.7,84.4 68.9,90.1 81.1,119.7 	"/>
</g>
        </svg>
  )
}

export default Icon
