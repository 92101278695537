import { Contract } from '@ethersproject/contracts'
// import BigNumber from 'bignumber.js'
import synthReactor from 'config/abi/SynthReactor.json'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { useCallback } from 'react'
// import { Deposit } from 'state/types'
import { getProviderOrSigner } from 'utils'
import { getSynthReactorAddress } from 'utils/addressHelpers'

const txResponseToArray = (tx) => {
  const result = tx.toString()
  if (result === '') return []
  return result.split(',')
}
export const useSynthReactorVault = () => {
  const { library, account, chainId } = useActiveWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()

  const getSynthReactorContract = useCallback(() => {
    return new Contract(getSynthReactorAddress(chainId), synthReactor, getProviderOrSigner(library, account))
  }, [library, account, chainId])

  const claimReward = useCallback(
    async (depositId) => {
      const tx = await callWithGasPrice(getSynthReactorContract(), 'claimReward', [depositId])
      return tx.wait()
    },
    [getSynthReactorContract, callWithGasPrice],
  )

  const deposit = useCallback(
    async (amount, depositId) => {
      const tx = await callWithGasPrice(getSynthReactorContract(), 'updateDeposit', [amount, depositId])
      return tx.wait()
    },
    [getSynthReactorContract, callWithGasPrice],
  )

  const withdraw = useCallback(
    async (amount, depositId) => {
      const tx = await callWithGasPrice(getSynthReactorContract(), 'unlock', [depositId])
      return tx.wait()
    },
    [getSynthReactorContract, callWithGasPrice],
  )

  const getDurations = useCallback(async () => {
    const tx = await callWithGasPrice(getSynthReactorContract(), 'lockModifiers')
    return txResponseToArray(tx)
  }, [getSynthReactorContract, callWithGasPrice])

  const addNewDeposit = useCallback(
    async (amount, durationIndex) => {
      const tx = await callWithGasPrice(getSynthReactorContract(), 'lock', [amount, durationIndex])
      return tx.wait()
    },
    [getSynthReactorContract, callWithGasPrice],
  )

  return {
    getDurations,
    claimReward,
    deposit,
    withdraw,
    addNewDeposit,
  }
}
