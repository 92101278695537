import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
    MAINNET = 1,
    TESTNET = 11155111,
    // RSK_MAINNET = 30,
    // RSK_TESTNET = 31,
    // BSC_MAINNET = 56,
    // BSC_TESTNET = 97,
    // OKC_MAINNET = 66,
}

export enum TradeType {
    EXACT_INPUT,
    EXACT_OUTPUT,
}

export enum Rounding {
    ROUND_DOWN,
    ROUND_HALF_UP,
    ROUND_UP,
}
// eric
export const FACTORY_ADDRESS = {
    [ChainId.MAINNET]: '0x74F65899961a8aC2F14c416252318DBe447184E6',
    [ChainId.TESTNET]: '0xb0E59f254B79EcC1674361eEeD685A4b85a67F80',
    // [ChainId.RSK_MAINNET]: '0x305f3bd2bf2425a8ba10588529b132f14d436a71',
    // [ChainId.RSK_TESTNET]: '0x907c82508cFb215D564161A618e196b218E4f375',
    // [ChainId.BSC_MAINNET]: '0x7e599C90C5D84DF399F8EfE3462a5F5C673c0ca8',
    // [ChainId.BSC_TESTNET]: '0x510085FDBD2123d3753Aa61C495af7d863746c24',
    // [ChainId.OKC_MAINNET]: '0x1ABFa8E80D33Cc250bbd2bCbac99bC84ACEAdb11',
}

export const INIT_CODE_HASH = {
    [ChainId.MAINNET]: '0x52c678c6048e998073c3caf18b929eb06ccce24d8f8aa82ba5b32f02f1470376',
    [ChainId.TESTNET]: '0x52c678c6048e998073c3caf18b929eb06ccce24d8f8aa82ba5b32f02f1470376',
    // [ChainId.RSK_MAINNET]: '0x039a6c55d612c34347963d19ff309e53bd5cd2c46f88b67b0f7f65d43193e92b',
    // [ChainId.RSK_TESTNET]: '0x039a6c55d612c34347963d19ff309e53bd5cd2c46f88b67b0f7f65d43193e92b',
    // [ChainId.BSC_MAINNET]: '0x039a6c55d612c34347963d19ff309e53bd5cd2c46f88b67b0f7f65d43193e92b',
    // [ChainId.BSC_TESTNET]: '0x039a6c55d612c34347963d19ff309e53bd5cd2c46f88b67b0f7f65d43193e92b',
    // [ChainId.OKC_MAINNET]: '0x039a6c55d612c34347963d19ff309e53bd5cd2c46f88b67b0f7f65d43193e92b',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(9975)
export const FEES_DENOMINATOR = JSBI.BigInt(10000)

export enum SolidityType {
    uint8 = 'uint8',
    uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
    [SolidityType.uint8]: JSBI.BigInt('0xff'),
    [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}
