import React from 'react'
import styled from 'styled-components'
import { mainnetTokens } from 'config/constants/tokens'
// import Helix from "../Svg/Icons/Helix";
import Text from '../Text/Text'
import Skeleton from '../Skeleton/Skeleton'
import { Colors } from '../../theme'

export interface Props {
  color?: keyof Colors
  cakePriceUsd?: number
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`
const WavxIconPrice = styled.div`
  padding-right: 10px;
`
const CakePrice: React.FC<Props> = ({ cakePriceUsd, color = 'textSubtle' }) => {
  const WAVX = mainnetTokens.helix
  return cakePriceUsd ? (
    <PriceLink href={`https://wavx.finance/swap?outputCurrency=${WAVX.address}`} target="_blank">
      {/* <Helix width="24px" mr="8px" /> */}
      <WavxIconPrice>
        <img src="/images/WAVX_32.png" alt="WAVX Tokens" />
      </WavxIconPrice>
      <Text color={color} bold>{`$${cakePriceUsd.toFixed(3)}`}</Text>
    </PriceLink>
  ) : (
    <Skeleton width={80} height={24} />
  )
}

export default React.memo(CakePrice)
