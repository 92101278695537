import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 409.6 380.37" {...props}>
      <path d="M236.67,160.8A6.83,6.83,0,0,0,248,153.23l-6.58-9.87h41.86a6.83,6.83,0,0,0,0-13.65H241.45l6.58-9.87a6.83,6.83,0,1,0-11.36-7.57L223,132.75a6.82,6.82,0,0,0,0,7.57Z" transform="translate(-51.2 -61.44)"/>
      <path d="M344.87,226.55a6.43,6.43,0,0,0-6.44-4l-24.6.81a6.82,6.82,0,0,0,.46,13.64l11.85-.39-22.06,35.57a6.82,6.82,0,0,0,2.2,9.4,6.72,6.72,0,0,0,3.59,1,6.82,6.82,0,0,0,5.8-3.23l22.07-35.57,4.91,10.79a6.84,6.84,0,0,0,6.23,4,6.93,6.93,0,0,0,2.82-.61,6.84,6.84,0,0,0,3.38-9.05Z" transform="translate(-51.2 -61.44)"/>
      <path d="M188.47,289.45a6.85,6.85,0,0,0,6.22-4l10.2-22.4a6.82,6.82,0,0,0-12.42-5.66l-4.92,10.79-22.06-35.57a6.83,6.83,0,0,0-11.6,7.2L176,275.38,164.1,275c-3.53-.38-6.93,2.84-7,6.6a6.82,6.82,0,0,0,6.6,7l24.6.81Z" transform="translate(-51.2 -61.44)"/>
      <path d="M378.88,225.28A81.92,81.92,0,1,0,297,143.36,82,82,0,0,0,378.88,225.28Zm0-150.19a68.27,68.27,0,1,1-68.27,68.27A68.35,68.35,0,0,1,378.88,75.09Z" transform="translate(-51.2 -61.44)"/>
      <path d="M215,143.36a81.92,81.92,0,1,0-81.92,81.92A82,82,0,0,0,215,143.36Zm-81.92,68.27a68.27,68.27,0,1,1,68.27-68.27A68.35,68.35,0,0,1,133.12,211.63Z" transform="translate(-51.2 -61.44)"/>
      <path d="M252.59,278a81.92,81.92,0,1,0,81.92,81.92A82,82,0,0,0,252.59,278Zm0,150.18a68.27,68.27,0,1,1,68.26-68.26A68.34,68.34,0,0,1,252.59,428.15Z" transform="translate(-51.2 -61.44)"/>
      <path d="M305.6,359.33H256.82a1.21,1.21,0,0,0-1.18,1.46,1.23,1.23,0,0,0,.34.62l23.91,23.17a1.23,1.23,0,0,1,.34.62,1.21,1.21,0,0,1-.06.7,1.19,1.19,0,0,1-.45.54,1.16,1.16,0,0,1-.67.21h-50a2.37,2.37,0,0,1-1.7-.71,2.41,2.41,0,0,1-.71-1.7V334.43a2.4,2.4,0,0,1,.19-.92,2.31,2.31,0,0,1,.52-.79,2.39,2.39,0,0,1,1.7-.7h50a1.27,1.27,0,0,0,.68-.21,1.24,1.24,0,0,0,.44-.55,1.2,1.2,0,0,0,.06-.7,1.23,1.23,0,0,0-.34-.62l-24.25-23.53a2.37,2.37,0,0,0-1.7-.71,2.41,2.41,0,0,0-1.71.71l-51.15,51.22a2.4,2.4,0,0,0,0,3.41l51.15,51.22a2.45,2.45,0,0,0,1.71.71,2.41,2.41,0,0,0,1.7-.71l50.81-50.87a1.22,1.22,0,0,0,.33-.61,1.19,1.19,0,0,0-.07-.7,1.22,1.22,0,0,0-1.11-.75Z" transform="translate(-51.2 -61.44)" fill="#101411;"/>
      <path d="M134.55,85.52a2.51,2.51,0,0,0-3.45-.85,2.47,2.47,0,0,0-.85.85l-31.4,62.8a2.37,2.37,0,0,0,.89,3.14l31.4,19.33a2.37,2.37,0,0,0,2.51,0l31.4-19.33a2.37,2.37,0,0,0,.9-3.14ZM130,164.41l-24.37-15,24.37-15Zm0-35.68-23,14.18,23-46.06Zm4.83-31.88,23.05,46.06-23.05-14.18Zm0,67.56v-30l24.37,15Z" transform="translate(-51.2 -61.44)"/>
      <path d="M165.61,157.48a2.38,2.38,0,0,0-3.07-.46L132.4,175.57,102.25,157a2.36,2.36,0,0,0-3.06.46,2.43,2.43,0,0,0-.08,3.11l31.4,38.65a2.44,2.44,0,0,0,3.43.35,2.81,2.81,0,0,0,.34-.35l31.4-38.65A2.43,2.43,0,0,0,165.61,157.48ZM130,190.91l-18.16-22.35L130,179.75Zm4.83,0V179.75L153,168.56Z" transform="translate(-51.2 -61.44)"/>
      <path d="M380.18,102H349.91a3.79,3.79,0,0,0-3.79,3.78v26.49h-7.56a3.78,3.78,0,1,0,0,7.56h7.56v7.57h-7.56a3.79,3.79,0,1,0,0,7.57h7.56v26.48a3.79,3.79,0,0,0,3.79,3.79h30.27a41.65,41.65,0,0,0,40-30.27h9.18a3.79,3.79,0,1,0,0-7.57H421.6c.12-1.25.2-2.5.2-3.78s-.08-2.54-.2-3.79h7.76a3.78,3.78,0,1,0,0-7.56h-9.18A41.65,41.65,0,0,0,380.18,102Zm-26.49,7.57h26.49a34.06,34.06,0,0,1,32.06,22.7H353.69Zm26.49,68.1H353.69V155h58.55A34.06,34.06,0,0,1,380.18,177.69Zm34.05-34.05a34.08,34.08,0,0,1-.22,3.78H353.69v-7.57H414A34.26,34.26,0,0,1,414.23,143.64Z" transform="translate(-51.2 -61.44)"/>
    </Svg>
    
  )
}

export default Icon