import React from 'react'
import { SvgProps } from '../Svg/types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg width="255" height="257" viewBox="0 0 255 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M252.117 128.5H135.242C134.667 128.501 134.106 128.674 133.63 128.996C133.154 129.319 132.785 129.776 132.569 130.309C132.354 130.843 132.302 131.429 132.421 131.992C132.54 132.555 132.824 133.07 133.236 133.47L190.513 188.985C190.927 189.385 191.212 189.899 191.331 190.462C191.451 191.025 191.399 191.611 191.184 192.144C190.968 192.678 190.598 193.134 190.121 193.456C189.644 193.777 189.082 193.948 188.507 193.947L68.7421 193.947C67.2113 193.947 65.7432 193.338 64.6608 192.254C63.5783 191.171 62.9703 189.701 62.9703 188.168L62.9703 68.8319C62.9703 68.0731 63.1195 67.3216 63.4096 66.6205C63.6997 65.9194 64.1248 65.2823 64.6608 64.7457C65.1967 64.2091 65.833 63.7835 66.5333 63.4931C67.2336 63.2027 67.9842 63.0532 68.7421 63.0532L188.515 63.0532C189.09 63.0519 189.652 62.879 190.128 62.5567C190.604 62.2344 190.973 61.7773 191.189 61.2437C191.404 60.7102 191.455 60.1245 191.337 59.5615C191.218 58.9985 190.934 58.4837 190.522 58.083L132.42 1.69148C131.338 0.608411 129.87 0 128.34 0C126.809 0 125.342 0.608411 124.259 1.69148L1.68948 124.415C0.607693 125.499 0 126.968 0 128.5C0 130.032 0.607693 131.501 1.68948 132.585L124.259 255.309C125.342 256.392 126.809 257 128.34 257C129.87 257 131.338 256.392 132.42 255.309L254.157 133.436C254.56 133.032 254.834 132.517 254.945 131.956C255.056 131.396 254.999 130.815 254.78 130.287C254.562 129.759 254.193 129.308 253.719 128.99C253.245 128.672 252.687 128.501 252.117 128.5Z"
        fill="#ABBEFF"
      />
    </svg>
  )
}

export default Icon
