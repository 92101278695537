import React, { useMemo, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
// import { isUserRejected } from 'utils/sentry'
import useActiveWeb3React from './hooks/useActiveWeb3React'
import { BLOCKED_ADDRESSES } from './config/constants'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import App from './App'
import Providers from './Providers'

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

function Blocklist({ children }: { children: ReactNode }) {
  const { account } = useActiveWeb3React()
  const blocked: boolean = useMemo(() => Boolean(account && BLOCKED_ADDRESSES.indexOf(account) !== -1), [account])
  if (blocked) {
    return <div>Blocked address</div>
  }
  return <>{children}</>
}
/* Disable Sentry temporarily. */
Sentry.init({})
// Sentry.init({
//   // dsn: 'https://ed98e16b9d704c22bef92d24bdd5f3b7@o1092725.ingest.sentry.io/6111410',  
//   integrations: [
//     new Integrations.BrowserTracing(),
//     new Sentry.Integrations.Breadcrumbs({
//       console: process.env.NODE_ENV === 'production',
//     }),
//   ],
//   environment: process.env.NODE_ENV,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1,
//   beforeSend(event, hint) {
//     const error = hint?.originalException
//     if (error && isUserRejected(error)) {
//       return null
//     }
//     return event
//   },
//   ignoreErrors: [
//     'User denied transaction signature',
//     'Non-Error promise rejection captured',
//     'User rejected the transaction',
//     'cancelled',
//   ],
// })

ReactDOM.render(
  <React.StrictMode>
    <Blocklist>
      <Providers>
        <Updaters />
        <App />
      </Providers>
    </Blocklist>
  </React.StrictMode>,
  document.getElementById('root'),
)
