import { shadows } from '../../theme/base'
import { darkColors, lightColors } from '../../theme/colors'
import { TooltipTheme } from './types'

export const light: TooltipTheme = {
    background: '#101411',
    text: darkColors.text,
    boxShadow: shadows.tooltip,
}

export const dark: TooltipTheme = {
    background: '#101411',
    text: lightColors.text,
    boxShadow: shadows.tooltip,
}
