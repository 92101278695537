import { CHAIN_CONFIG } from "utils/types"
import { ChainId } from 'sdk'

export const CHAIN_IDS_TO_NAMES = {
    [ChainId.MAINNET]: 'ethereum',
    [ChainId.TESTNET]: 'sepolia',
    // [ChainId.RSK_MAINNET]: 'bitcoin_rsk',
    // [ChainId.RSK_TESTNET]: 'rsk_testnet',
    // [ChainId.BSC_MAINNET]: 'bsc',
    // [ChainId.BSC_TESTNET]: 'bsc_testnet',
    // [ChainId.OKC_MAINNET]: 'okc'
}

export const SUPPORTED_NETWORKS: { [key: number]: CHAIN_CONFIG } = {
    [ChainId.MAINNET]: {
        chainId: 1,
        chainName: CHAIN_IDS_TO_NAMES[ChainId.MAINNET],
        chainNameForMetamask: "Ethereum Mainnet",
        nativeCurrency: {
            name: 'Ethereum',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/dd4d5ab90ee44af2a252da6a1a25d30b'],
        rpcUrlsForMetamask: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.com'],
        logoUrl: '/images/networks/mainnet-network.jpg',
        label: 'Ethereum',
        showOnlyTrade: false,
        isTestChain: false,
        apiKey: "dd4d5ab90ee44af2a252da6a1a25d30b"
    },
    [ChainId.TESTNET]: {
        chainId: 11155111,
        chainName: CHAIN_IDS_TO_NAMES[ChainId.TESTNET],
        chainNameForMetamask: "Sepolia Testnet",
        nativeCurrency: {
            name: 'Sepolia Ethereum',
            symbol: 'sETH',
            decimals: 18,
        },
        rpcUrls: ['https://eth-sepolia.g.alchemy.com/v2/uxQAUA1Zit6qrUhsxNeGHJMBSh4pRS7W'],
        rpcUrlsForMetamask: ['https://sepolia.infura.io/v3/'],
        blockExplorerUrls: ['https://sepolia.etherscan.io/'],
        logoUrl: '/images/networks/sepolia-network.png',
        label: 'Sepolia',
        showOnlyTrade: false,
        isTestChain: true,
        apiKey: "uxQAUA1Zit6qrUhsxNeGHJMBSh4pRS7W"
    },
    // [ChainId.RSK_MAINNET]: {
    //     chainId: 30,
    //     chainName: CHAIN_IDS_TO_NAMES[ChainId.RSK_MAINNET],
    //     chainNameForMetamask: "RSK Mainnet",
    //     nativeCurrency: {
    //         name: 'RSK Smart Bitcoin',
    //         symbol: 'RBTC',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['https://helix-rsk-node.com'],
    //     rpcUrlsForMetamask: ['https://helix-rsk-node.com'],
    //     blockExplorerUrls: ['https://explorer.rsk.co'],
    //     logoUrl: '/images/networks/rsk-network.jpg',
    //     label: 'Bitcoin RSK',
    //     showOnlyTrade: true,
    //     isTestChain: false
    // },
    // [ChainId.RSK_TESTNET]: {
    //     chainId: 31,
    //     chainName: CHAIN_IDS_TO_NAMES[ChainId.RSK_TESTNET],
    //     chainNameForMetamask: "RSK Testnet",
    //     nativeCurrency: {
    //         name: 'Test RSK Smart Bitcoin',
    //         symbol: 'tRBTC',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['https://public-node.testnet.rsk.co'],
    //     rpcUrlsForMetamask: ['https://public-node.testnet.rsk.co'],
    //     blockExplorerUrls: ['https://explorer.testnet.rsk.co'],
    //     logoUrl: '/images/networks/rsk-network.jpg',
    //     label: 'RSK Testnet',
    //     showOnlyTrade: true,
    //     isTestChain: true
    // },
    // [ChainId.BSC_MAINNET]: {
    //     chainId: 56,
    //     chainName: CHAIN_IDS_TO_NAMES[ChainId.BSC_MAINNET],
    //     chainNameForMetamask: "BSC",
    //     nativeCurrency: {
    //         name: 'BNB',
    //         symbol: 'BNB',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['https://multi-polished-frog.bsc.quiknode.pro/a88df2a809c021090a74b62200b5cd2ba73a7bcb/'],
    //     rpcUrlsForMetamask: ['https://bsc-dataseed.binance.org/'],
    //     blockExplorerUrls: ['https://bscscan.com'],
    //     logoUrl: '/images/networks/bnb-network.png',
    //     label: 'BSC',
    //     showOnlyTrade: true,
    //     isTestChain: false
    // },
    // [ChainId.BSC_TESTNET]: {
    //     chainId: 97,
    //     chainName: CHAIN_IDS_TO_NAMES[ChainId.BSC_TESTNET],
    //     chainNameForMetamask: "BSC Testnet",
    //     nativeCurrency: {
    //         name: 'Test BNB',
    //         symbol: 'tBNB',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['https://sleek-red-patina.bsc-testnet.quiknode.pro/4bb5df7d53d77afb1de5c12aba9ab66a2b993520/'],
    //     rpcUrlsForMetamask: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    //     blockExplorerUrls: ['https://testnet.bscscan.com'],
    //     logoUrl: '/images/networks/bnb-network.jpg',
    //     label: 'BSC Testnet',
    //     showOnlyTrade: true,
    //     isTestChain: true
    // },
    // [ChainId.OKC_MAINNET]: {
    //     chainId: 66,
    //     chainName: CHAIN_IDS_TO_NAMES[ChainId.OKC_MAINNET],
    //     chainNameForMetamask: "OKExChain",
    //     nativeCurrency: {
    //         name: 'OKT',
    //         symbol: 'OKT',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['https://exchainrpc.okex.org/'],
    //     rpcUrlsForMetamask: ['https://exchainrpc.okex.org/'],
    //     blockExplorerUrls: ['https://www.oklink.com/en/okc/'],
    //     logoUrl: '/images/networks/okc.png',
    //     label: 'OKC',
    //     showOnlyTrade: true,
    //     isTestChain: false
    // }
}
