import { MenuItemsType } from 'uikit'
import { DropdownMenuItemType } from 'uikit/components/DropdownMenu/types'
import { ContextApi } from 'contexts/Localization/types'
import { ChainId } from 'sdk'

export type ConfigMenuItemType = MenuItemsType & { hideSubNav?: boolean; onlyAdmin?: boolean }
export type ConfigMenuItemsType = {
    [ChainId.MAINNET]: ConfigMenuItemType[]
    [ChainId.TESTNET]: ConfigMenuItemType[]
    // [ChainId.RSK_MAINNET]: ConfigMenuItemType[]
    // [ChainId.RSK_TESTNET]: ConfigMenuItemType[]
    // [ChainId.BSC_MAINNET]: ConfigMenuItemType[]
    // [ChainId.BSC_TESTNET]: ConfigMenuItemType[]
    // [ChainId.OKC_MAINNET]: ConfigMenuItemType[]
}

const config: (t: ContextApi['t']) => ConfigMenuItemsType = (t) => {
    return {
        [ChainId.MAINNET]: [
            {
                label: t('Swap'),
                icon: 'Swap',
                href: '/swap',
                showItemsOnMobile: false,
            },
            {
                label: t('Liquidity'),
                icon: 'TicketFill',
                href: '/liquidity',
                showItemsOnMobile: false,
            },
            // {
            //     label: t('Migrate'),
            //     icon: 'Vote1',
            //     href: '/migration',
            // },
        ],
        [ChainId.TESTNET]: [
            {
                label: t('Swap'),
                icon: 'Swap',
                href: '/swap',
            },
            {
                label: t('Liquidity'),
                icon: 'TicketFill',
                href: '/liquidity',
            },
            // {
            //     label: t('Migrate'),
            //     icon: 'Vote1',
            //     href: '/migration',
            // },
        ],
    }
}

export default config
