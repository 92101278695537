import { ExternalRouterData } from 'config/constants/externalRouters'
import { useMemo } from 'react'
import { Token } from 'sdk'
import { isAddress } from '../../utils'

export function filterTokens(tokens: Token[], search: string): Token[] {
    if (search.length === 0) return tokens

    const searchingAddress = isAddress(search)

    if (searchingAddress) {
        return tokens.filter((token) => token.address === searchingAddress)
    }

    const lowerSearchParts = search
        .toLowerCase()
        .split(/\s+/)
        .filter((s) => s.length > 0)

    if (lowerSearchParts.length === 0) {
        return tokens
    }

    const matchesSearch = (s: string): boolean => {
        const sParts = s
            .toLowerCase()
            .split(/\s+/)
            .filter((s_) => s_.length > 0)

        return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
    }

    return tokens.filter((token) => {
        const { symbol, name } = token
        return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name))
    })
}

export function useSortedTokensByQuery(tokens: Token[] | undefined, searchQuery: string): Token[] {
    return useMemo(() => {
        if (!tokens) {
            return []
        }

        const symbolMatch = searchQuery
            .toLowerCase()
            .split(/\s+/)
            .filter((s) => s.length > 0)

        if (symbolMatch.length > 1) {
            return tokens
        }

        const exactMatches: Token[] = []
        const symbolSubstrings: Token[] = []
        const rest: Token[] = []

        // sort tokens by exact match -> substring on symbol match -> rest
        tokens.map((token) => {
            if (token.symbol?.toLowerCase() === symbolMatch[0]) {
                return exactMatches.push(token)
            }
            if (token.symbol?.toLowerCase().startsWith(searchQuery.toLowerCase().trim())) {
                return symbolSubstrings.push(token)
            }
            return rest.push(token)
        })

        return [...exactMatches, ...symbolSubstrings, ...rest]
    }, [tokens, searchQuery])
}

export function useFilterDexByQuery(dexes: ExternalRouterData[], searchQuery: string): ExternalRouterData[] {
    return useMemo(() => {
        const symbolMatch = searchQuery
            .toLowerCase()
            .split(/\s+/)
            .filter((s) => s.length > 0)

        if (symbolMatch.length > 1) {
            return dexes
        }

        const exactMatches: ExternalRouterData[] = []
        const symbolSubstrings: ExternalRouterData[] = []
        const rest: ExternalRouterData[] = []

        // sort tokens by exact match -> substring on symbol match -> rest
        dexes.map((dex) => {
            if (dex.pairToken.symbol?.toLowerCase() === symbolMatch[0]) {
                return exactMatches.push(dex)
            }
            if (dex.pairToken.symbol?.toLowerCase().startsWith(searchQuery.toLowerCase().trim())) {
                return symbolSubstrings.push(dex)
            }
            return rest.push(dex)
        })

        return [...exactMatches, ...symbolSubstrings, ...rest]
    }, [dexes, searchQuery])
}
