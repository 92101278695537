import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="352" height="162" viewBox="0 0 352 352" fill="none" {...props}>
      <path
        d="M15 0C6.71573 0 0 6.71573 0 15V337C0 345.284 6.71573 352 15 352H337C345.284 352 352 345.284 352 337V15C352 6.71573 345.284 0 337 0H15ZM185.117 176H269.908C270.322 176.001 270.727 176.126 271.071 176.358C271.414 176.591 271.682 176.921 271.841 177.307C271.999 177.694 272.04 178.118 271.96 178.528C271.879 178.938 271.681 179.315 271.388 179.611L183.069 268.763C182.284 269.555 181.219 270 180.109 270C178.999 270 177.934 269.555 177.149 268.763L88.2257 178.988C87.4409 178.196 87 177.121 87 176C87 174.879 87.4409 173.804 88.2257 173.012L177.149 83.2373C177.934 82.4451 178.999 82 180.109 82C181.219 82 182.284 82.4451 183.069 83.2373L225.222 124.489C225.521 124.782 225.727 125.158 225.813 125.57C225.899 125.982 225.862 126.411 225.705 126.801C225.549 127.191 225.281 127.526 224.936 127.761C224.59 127.997 224.183 128.124 223.766 128.125H136.872C136.322 128.125 135.777 128.234 135.269 128.446C134.761 128.659 134.3 128.97 133.911 129.363C133.522 129.755 133.213 130.221 133.003 130.734C132.793 131.247 132.684 131.797 132.684 132.352V219.648C132.684 220.769 133.125 221.845 133.911 222.637C134.696 223.43 135.761 223.876 136.872 223.876H223.76C224.177 223.876 224.585 223.751 224.931 223.516C225.277 223.281 225.545 222.947 225.702 222.557C225.858 222.167 225.896 221.738 225.809 221.326C225.722 220.914 225.516 220.538 225.215 220.246L183.661 179.636C183.362 179.343 183.156 178.966 183.07 178.554C182.984 178.142 183.021 177.714 183.178 177.324C183.334 176.933 183.602 176.599 183.947 176.363C184.293 176.127 184.7 176.001 185.117 176Z"
        fill="#ABBEFF"
      />
    </svg>
  )
}

export default Icon
