import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 398.77 423.25" {...props}>
      <path d="M258,303.79a81.92,81.92,0,1,0,81.92,81.92A82,82,0,0,0,258,303.79ZM258,454a68.27,68.27,0,1,1,68.27-68.26A68.34,68.34,0,0,1,258,454Z" transform="translate(-56.61 -44.37)"/>
      <path d="M242.35,76.51l8.82-8.83V290.13a6.83,6.83,0,1,0,13.66,0V67.68l8.82,8.83a6.83,6.83,0,1,0,9.66-9.66L262.83,46.37a6.84,6.84,0,0,0-9.66,0L232.69,66.85a6.83,6.83,0,1,0,9.66,9.66Z" transform="translate(-56.61 -44.37)"/>
      <path d="M277.07,291.55a6.82,6.82,0,0,0,9.65,0l17.07-17.07a6.82,6.82,0,0,0,2-4.83v-120L356.4,99h45l-7.65,7.65a6.82,6.82,0,1,0,9.65,9.65L422.67,97a6.84,6.84,0,0,0,0-9.66L403.36,68a6.82,6.82,0,1,0-9.65,9.65l7.65,7.65H353.57a6.81,6.81,0,0,0-4.82,2L294.13,142a6.81,6.81,0,0,0-2,4.82V266.83l-15.06,15.06A6.83,6.83,0,0,0,277.07,291.55Z" transform="translate(-56.61 -44.37)"/>
      <path d="M112.06,116.29a6.82,6.82,0,0,0,9.65-9.65L114.06,99H159l50.62,50.61v120a6.82,6.82,0,0,0,2,4.83l17.06,17.07a6.83,6.83,0,1,0,9.66-9.66l-15.07-15.06V146.77a6.81,6.81,0,0,0-2-4.82L166.67,87.33a6.84,6.84,0,0,0-4.83-2H114.06l7.65-7.65A6.82,6.82,0,0,0,112.06,68L92.75,87.33a6.83,6.83,0,0,0,0,9.66Z" transform="translate(-56.61 -44.37)"/>
      <path d="M189.15,256A6.83,6.83,0,0,0,194,244.35l-18.48-18.48V194.56a6.84,6.84,0,0,0-2-4.83l-41-41a6.81,6.81,0,0,0-4.82-2H79.92l7.66-7.65a6.83,6.83,0,0,0-9.66-9.65l-19.31,19.3a6.83,6.83,0,0,0,0,9.66l19.31,19.3a6.83,6.83,0,1,0,9.66-9.65l-7.66-7.65h45l37,37v31.3a6.82,6.82,0,0,0,2,4.83L184.32,254a6.81,6.81,0,0,0,4.83,2Z" transform="translate(-56.61 -44.37)"/>
      <path d="M453.39,148.77l-19.31-19.3a6.82,6.82,0,0,0-9.65,9.65l7.65,7.65H384.29a6.81,6.81,0,0,0-4.82,2l-41,41a6.84,6.84,0,0,0-2,4.83v31.31L318,244.35a6.82,6.82,0,1,0,9.65,9.65l20.48-20.48a6.82,6.82,0,0,0,2-4.83v-31.3l37-37h45l-7.65,7.65a6.82,6.82,0,1,0,9.65,9.65l19.31-19.3a6.84,6.84,0,0,0,0-9.66Z" transform="translate(-56.61 -44.37)"/>
      <path d="M311,385.15H262.23a1.2,1.2,0,0,0-1.11.76,1.2,1.2,0,0,0-.07.7,1.23,1.23,0,0,0,.34.62L285.3,410.4a1.23,1.23,0,0,1,.34.62,1.2,1.2,0,0,1-.06.7,1.24,1.24,0,0,1-.44.55,1.26,1.26,0,0,1-.68.2h-50a2.41,2.41,0,0,1-2.41-2.41V360.25a2.39,2.39,0,0,1,.18-.92,2.47,2.47,0,0,1,.52-.79,2.54,2.54,0,0,1,.78-.52,2.48,2.48,0,0,1,.93-.18h50a1.23,1.23,0,0,0,.67-.21,1.21,1.21,0,0,0,.5-1.25,1.09,1.09,0,0,0-.34-.62l-24.25-23.53a2.39,2.39,0,0,0-3.4,0l-51.16,51.22a2.4,2.4,0,0,0,0,3.41l51.16,51.22a2.39,2.39,0,0,0,3.4,0l50.81-50.87a1.19,1.19,0,0,0,.26-1.31,1.16,1.16,0,0,0-.44-.54A1.23,1.23,0,0,0,311,385.15Z" transform="translate(-56.61 -44.37)" fill="#101411;"/>
    </Svg>
    
  )
}

export default Icon