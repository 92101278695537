import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg width="20px" height="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" fill="#FFFFFF">         
        <g>
            <path d="M4.5,256.8l39.6,22.9c4,2.3,8.8,1.5,11.8-1.6l29.6,17.1c-6.1,19.4,7.3,39.3,27.5,41
                c-4.4,16.7,5.3,34.1,22.4,38.7c4.6,1.2,9.4,1.4,14.2,0.5c-1.8,18.6,12.9,34.7,31.5,34.7c5.7,0,11.2-1.6,15.8-4.2
                c0.9,16.6,14.7,29.9,31.6,29.9c5.4,0,10.8-1.4,15.8-4.2c6.8-3.9,7.5-4.3,9.2-5.5l17.7,10.2c7.5,4.3,16.6,5.4,24.9,3.1
                c14.4-4,23.5-17,23-31.2c15.1,8.2,34.7,3.3,43.3-12.2c2.9-5.3,4.1-11.1,3.9-16.8c6.4,2.4,13.3,2.7,20,0.7c17-5.1,27-23.8,20.3-41.6
                c8.7-1.7,16.6-7,21.4-15.2c0.1-0.1,0.1-0.3,0.2-0.4c4.5-9.1,4.4-19.6,0.4-28.5l27.6-16c3,3,7.8,3.9,11.8,1.6l39.6-22.9
                c2.8-1.6,4.5-4.6,4.5-7.8V80.4c0-6.9-7.5-11.2-13.4-7.8l-112.7,65.1c-4,2.3-5.7,6.9-4.5,11L351.5,166c-2.4,1.4-4,3.7-4.6,6.2
                c-10.1,5.7-22.5,5.7-32.5-0.1l-33.1-19.1c-14.9-8.6-33.4-8.6-48.3,0l-45.3,26.2c-7.9-0.5-15.3-2.8-22.5-7c-0.6-2.5-2.2-4.8-4.6-6.2
                l-29.9-17.2c1.1-4.1-0.5-8.7-4.5-11L91.9,118c-3.6-2.1-8.2-0.8-10.2,2.7s-0.8,8.2,2.7,10.2l29.6,17.1l-67,116L15,245.6V90.9
                l38.5,22.2c3.6,2.1,8.2,0.8,10.2-2.7s0.8-8.2-2.7-10.2L13.4,72.6C7.5,69.2,0,73.5,0,80.4v168.7C0,252.3,1.7,255.3,4.5,256.8
                L4.5,256.8z M101.3,313.1c-4.6-7.9-1.9-18.1,6.1-22.7l21.5-12.4c7.9-4.6,18.1-1.9,22.7,6.1s1.9,18.1-6.1,22.7
                c-20.2,11.7-1,0.6-21.5,12.4C116.1,323.8,105.9,321,101.3,313.1z M139.3,360.5c-8.9-2.4-14.1-11.5-11.8-20.4
                c1.1-4.3,3.9-7.9,7.8-10.1c8.7-5,5.4-3.1,12.8-7.4c7.9-4.6,18.1-1.8,22.7,6.1c4.6,7.9,1.9,18.1-6.1,22.7l-12.7,7.4
                C148,361.1,143.5,361.6,139.3,360.5L139.3,360.5z M166.6,386.9c-4.6-7.9-1.9-18.1,6.1-22.7l11.5-6.6c3.9-2.2,8.3-2.8,12.6-1.7
                c8.9,2.4,14.1,11.5,11.8,20.4c-1.1,4.2-3.8,7.8-7.8,10.1l-11.5,6.6C181.4,397.5,171.2,394.8,166.6,386.9L166.6,386.9z M243.2,414.8
                l-6.5,3.8c-7.9,4.6-18.1,1.9-22.7-6.1c-4.6-8-1.8-18.2,6.1-22.7l6.5-3.8c8-4.6,18.2-1.8,22.7,6.1
                C253.9,400,251.2,410.2,243.2,414.8z M388.6,161.9l33.1,57.4c2.1,3.6,6.7,4.8,10.2,2.7c3.6-2.1,4.8-6.7,2.7-10.2l-36.7-63.6
                L497,90.9v154.7l-32.1,18.5l-12.4-21.5c-2.1-3.6-6.7-4.8-10.2-2.7c-3.6,2.1-4.8,6.7-2.7,10.2l8.8,15.2l-25.1,14.5l-59.7-103.5
                L388.6,161.9z M172.9,191.7c-11.7,18.2-2.1,42.2,18.4,47.7c8.2,2.2,16.7,1.1,24-3.2l46.7-27c0.6-0.4,1.4-0.4,2,0l23.4,13.5
                c3.6,2.1,8.2,0.8,10.2-2.7c2.1-3.6,0.8-8.2-2.7-10.2l-23.4-13.5c-5.2-3-11.7-3-17,0l-46.7,27c-7.9,4.6-18.1,1.9-22.7-6.1
                s-1.9-18.1,6.1-22.7c103.6-59.8,1.8-1,49.3-28.5c10.3-5.9,23-5.9,33.3,0l33.1,19.1c13.9,8,30.9,8.5,45.1,1.4l48.7,84.4l-74.6-43.1
                c-3.6-2.1-8.2-0.8-10.2,2.7c-2.1,3.6-0.8,8.2,2.7,10.2l88.8,51.3c8,4.6,11.3,15.4,7.3,23.7c-4.6,7.8-14.7,10.4-22.6,5.9
                c-1.1-0.8,9,5.2-53.5-30.9c-3.6-2.1-8.2-0.8-10.2,2.7c-2.1,3.6-0.8,8.2,2.7,10.2c0.5,0.3,53.5,30.9,54.3,31.3
                c7.3,4.2,10.3,14.2,6.5,21.9c-4.4,8.7-14.7,11.8-23.1,7l-18.3-10.6c0,0,0,0,0,0l-36-20.8c-3.6-2.1-8.2-0.8-10.2,2.7
                s-0.8,8.2,2.7,10.2l36,20.8c7.8,4.5,10.6,14.6,6.2,22.4c-4.5,8.2-15.3,10.8-23.4,6.1c-1.2-0.7-35.1-20.2-35.5-20.5
                c-3.6-2.1-8.2-0.8-10.2,2.7c-2.1,3.6-0.8,8.2,2.7,10.2l12.9,7.5c8,4.7,10.5,14.8,6.2,22.4c-4.6,8.3-15.4,10.7-23.4,6.1l-15.3-8.9
                c4.5-9.2,4.5-20.3-1-29.8c-7.8-13.5-24.1-19.1-38.2-13.9c-0.7-18.4-17.4-33-36.4-30.1c1.7-15.3-7.7-29.5-22.4-33.9
                c4.7-9.2,4.8-20.6-0.8-30.2c-8.7-15.1-28.1-20.3-43.2-11.6l-10.1,5.8l48.7-84.4C164.1,188.6,168.4,190.4,172.9,191.7L172.9,191.7z
                M88.7,279.8l-25.1-14.5l59.7-103.5l25.1,14.5L88.7,279.8z"/>
        </g>
    </svg>
  )
}

export default Icon
