import React from 'react'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <svg width="20px" height="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" fill="#FFFFFF">         
        <path d="M312.8,230.3c-3,0.6-11.1,2.1-20.3,4.5c1-3.4,1.6-6.9,1.6-10.5c0-18.2-26.3-57.2-31.6-64.8
	c-2.8-3.6-8-4.3-11.6-1.5c-0.6,0.4-1.1,1-1.5,1.5c-6.6,11-38.8,53.5-29.9,75.3c-9.2-2.4-17.3-3.9-20.3-4.5c-2.6-0.5-5.2,0.3-7.1,2.2
	c-1.9,1.9-2.7,4.5-2.2,7.1c1.4,7.4,8.6,44.7,19.3,55.4c10.3,10.3,26.3,12.4,38.8,4.9V348c0.1,4.4,3.7,7.9,8.2,7.8
	c4.3-0.1,7.8-3.5,7.8-7.8v-48.1c12.5,7.4,28.5,5.4,38.8-4.9c10.6-10.6,17.9-48,19.3-55.4c0.8-4.4-2.2-8.5-6.6-9.3
	C314.6,230.1,313.7,230.1,312.8,230.3L312.8,230.3z M233.8,224.3c0-8,11.4-29,22.1-45.8c10.7,16.8,22.1,37.8,22.1,45.8
	c-0.2,12.2-10.2,22-22.4,21.8C243.7,246,234,236.3,233.8,224.3L233.8,224.3z M220.5,283.6c-3.8-3.8-8.8-19.4-12.4-35.2
	c12.4,2.1,24.3,6.3,35.2,12.4c6.2,6.4,6.1,16.6-0.3,22.8C236.7,289.7,226.7,289.7,220.5,283.6L220.5,283.6z M291.5,283.6
	c-6.3,6.2-16.5,6.1-22.7-0.2c-6.2-6.3-6.1-16.5,0.2-22.7c0.5-0.5,1-0.9,1.5-1.3c10.6-5.1,21.9-8.8,33.4-11.1
	C300.3,264.2,295.3,279.9,291.5,283.6L291.5,283.6z"/>
<path d="M256,115.8c-77.4,0.1-140.2,62.8-140.2,140.2c7.7,186.1,272.8,186,280.5,0C396.1,178.6,333.4,115.8,256,115.8z
	 M256,380.2c-68.6-0.1-124.2-55.7-124.2-124.2c6.8-164.8,241.7-164.8,248.5,0C380.1,324.6,324.6,380.2,256,380.2z"/>
<path d="M423.1,114.9c1.1-2,1.3-4.4,0.6-6.5V80.7c-0.1-4.4-3.7-7.9-8.2-7.8c-4.3,0.1-7.8,3.5-7.8,7.8v10.5
	c-74.6-68.7-186-78.7-271.6-24.4c-3.7,2.4-4.7,7.4-2.2,11.1c2.4,3.6,7.2,4.6,10.8,2.4c79.9-50.7,183.9-41,253.1,23.6l-13.3,0
	c-4.4,0-8,3.6-8,8s3.6,8,8,8h31.2C418.9,119.9,421.8,117.9,423.1,114.9L423.1,114.9z"/>
<path d="M103.9,114.4v13.1c0.1,4.4,3.7,7.9,8.2,7.8c4.3-0.1,7.8-3.5,7.8-7.8V96.3c-0.2-2.3-1.1-4.5-2.6-6.2
	c-2.4-2.6-6.1-3.3-9.2-1.8H80.7c-4.4,0.1-7.9,3.7-7.8,8.2c0.1,4.3,3.5,7.8,7.8,7.8h10.8c-69.4,74.7-79.3,186.9-24,272.6
	c2.5,3.7,7.4,4.6,11.1,2.2c3.6-2.4,4.6-7.2,2.4-10.8C29.4,288.4,38.9,183.7,103.9,114.4L103.9,114.4z"/>
<path d="M433.4,132.5c-3.7,2.4-4.8,7.3-2.4,11c0,0,0,0,0,0c51.5,79.9,42.1,184.6-22.9,254l0-13c0-4.4-3.6-8-8-8
	s-8,3.6-8,8v31.2c0,4.4,3.6,8,8,8c0,0,0,0,0,0h31.2c4.4,0,8-3.6,8-8s-3.6-8-8-8c0,0,0,0,0,0h-10.8c69.4-74.8,79.2-187.1,24-272.8
	C442,131.2,437.1,130.1,433.4,132.5L433.4,132.5z"/>
<path d="M369.3,430.4c-80,51.9-185.1,42.7-254.9-22.4l13.1,0c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8H96.3c-4.4,0-8,3.6-8,8
	c0,0,0,0,0,0v31.2c0.1,4.4,3.7,7.9,8.1,7.9c4.3-0.1,7.8-3.5,7.9-7.9v-10.5c75.2,69.4,188,78.9,273.7,23.1c3.7-2.5,4.6-7.5,2.1-11.1
	C377.8,429.2,373,428.2,369.3,430.4z"/>

        </svg>
  )
}

export default Icon
