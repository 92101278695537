import { gql } from 'graphql-request'
import { useState, useEffect } from 'react'
import { multiQuery } from 'views/Info/utils/infoQueryHelpers'
import { BLOCKS_CLIENT } from 'config/constants/endpoints'
import { Block } from 'state/info/types'
import { ChainId } from 'sdk'
import usePreviousValue from 'hooks/usePreviousValue'
import { useChainIdData } from 'state/info/hooks'

const getBlockSubqueries = (timestamps: number[]) =>
    timestamps.map((timestamp) => {
        return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${timestamp + 600
            } }) {
      number
    }`
    })

const blocksQueryConstructor = (subqueries: string[]) => {
    return gql`query blocks {
    ${subqueries}
  }`
}

/**
 * @notice Fetches block objects for an array of timestamps.
 * @param {Array} timestamps
 */
export const getBlocksFromTimestamps = async (
    chainId: ChainId,
    timestamps: number[],
    sortDirection: 'asc' | 'desc' = 'desc',
    skipCount = 1000,
): Promise<Block[]> => {
    if (timestamps?.length === 0) {
        return []
    }

    const fetchedData: any = await multiQuery(
        blocksQueryConstructor,
        getBlockSubqueries(timestamps),
        BLOCKS_CLIENT[chainId],
        skipCount,
    )

    const sortingFunction =
        sortDirection === 'desc'
            ? (a: Block, b: Block) => b.number - a.number
            : (a: Block, b: Block) => a.number - b.number

    const blocks: Block[] = []
    if (fetchedData) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(fetchedData)) {
            if (fetchedData[key].length > 0) {
                blocks.push({
                    timestamp: key.split('t')[1],
                    number: parseInt(fetchedData[key][0].number, 10),
                })
            }
        }
        // graphql-request does not guarantee same ordering of batched requests subqueries, hence manual sorting
        blocks.sort(sortingFunction)
    }
    return blocks
}

/**
 * for a given array of timestamps, returns block entities
 * @param timestamps
 * @param sortDirection
 * @param skipCount
 */
export const useBlocksFromTimestamps = (
    timestamps: number[],
    sortDirection: 'asc' | 'desc' = 'desc',
    skipCount = 1000,
): {
    blocks?: Block[]
    error: boolean
} => {
    const [blocks, setBlocks] = useState<Block[]>()
    const [error, setError] = useState(false)
    const { chainId } = useChainIdData()
    const prevChainId = usePreviousValue(chainId)
    const timestampsString = JSON.stringify(timestamps)
    const blocksString = blocks ? JSON.stringify(blocks) : undefined

    useEffect(() => {
        if (chainId !== prevChainId) {
            setBlocks(undefined)
            setError(false)
        }
    }, [chainId, prevChainId])

    useEffect(() => {
        const fetchData = async () => {
            const timestampsArray = JSON.parse(timestampsString)
            const result = await getBlocksFromTimestamps(chainId, timestampsArray, sortDirection, skipCount)
            if (result.length === 0) {
                setError(true)
            } else {
                setBlocks(result)
            }
        }
        const blocksArray = blocksString ? JSON.parse(blocksString) : undefined
        if ((!blocksArray && !error) || prevChainId !== chainId) {
            fetchData()
        }
    }, [blocksString, chainId, error, skipCount, sortDirection, timestampsString, prevChainId])

    return {
        blocks,
        error,
    }
}
